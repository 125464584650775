import React from 'react';
import { Link } from 'react-router-dom';
import Share from './Share';
import './Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="hero-section">
        <h1 className="welcome-title">
          Welcome to Kindergarten Corner!
          <img src="/favicon/favicon.svg" alt="KG Corner Logo" className="welcome-logo" />
        </h1>
        <p className="subtitle">A fun place to learn and grow together</p>
       
      </div>

      <div className="features-section">
        <h2>Let's Start Learning</h2>
        <div className="tiles-container single-tile">
          <Link to="/spellings" className="tile">
            <div className="tile-content">
              <div className="tile-icon">📚</div>
              <h3>Spellings</h3>
              <p>Learn to spell words through fun activities</p>
              <ul className="feature-list">
                <li>Basic to Advanced levels</li>
                <li>Interactive practice</li>
                <li>Voice assistance</li>
              </ul>
              <div className="tile-cta">Start Learning →</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="welcome-footer">
        <p>Ready to begin your learning adventure? Let's start with Spellings!</p>
        <div className="mascot">👋</div>
        <div className="contact-info">
          <p className="contact-message">Have questions or suggestions? We'd love to hear from you! 💌</p>
          <p className="contact-email">
            <span className="contact-label">Write to us at: </span>
            <a href="mailto:vibecdev@gmail.com">vibecdev@gmail.com</a>
          </p>
        </div>
      </div>
      <Share title="Parents love us. Kids adore us.  Kindergarten Corner is more than a place to learn — it’s where curiosity takes flight!" />
    </div>
  );
}

export default Home; 