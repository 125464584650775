import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Stories from './components/Stories';
import WordLevel from './components/WordLevel';
import Spellings from './components/Spellings';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/stories" element={<Stories />} />
      <Route path="/spellings" element={<Spellings />} />
      <Route path="/spellings/:category" element={<WordLevel />} />
    </Routes>
  );
}

export default App; 