import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import storiesData from '../data/stories.json';
import './Stories.css';

function Stories() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    // Update the document title based on the current view
    if (selectedStory) {
      document.title = `${selectedStory.title} - Kids Corner`;
    } else if (selectedCategory) {
      document.title = `${storiesData[selectedCategory].title} - Kids Corner`;
    } else {
      document.title = 'Stories - Kids Corner';
    }

    // Cleanup function to reset title when component unmounts
    return () => {
      document.title = 'Kids Corner';
    };
  }, [selectedCategory, selectedStory]);

  const handleCategoryClick = (categoryKey) => {
    setSelectedCategory(categoryKey);
    setSelectedStory(null);
  };

  const handleStoryClick = (story) => {
    setSelectedStory(story);
  };

  const handleBackClick = () => {
    if (selectedStory) {
      setSelectedStory(null);
    } else if (selectedCategory) {
      setSelectedCategory(null);
    }
  };

  const renderStoryContent = () => {
    return (
      <div className="story-content">
        <div className="story-header">
          <h2>
            <i className="fas fa-book-open story-icon"></i>
            {selectedStory.title} {selectedStory.thumbnail}
          </h2>
          <div className="story-meta">
            <span><i className="fas fa-graduation-cap"></i> Reading Level: {selectedStory.readingLevel}</span>
            <span><i className="fas fa-clock"></i> Duration: {selectedStory.duration}</span>
            {selectedStory.moral && (
              <span><i className="fas fa-heart"></i> Moral: {selectedStory.moral}</span>
            )}
          </div>
        </div>
        <div className="story-paragraphs">
          {selectedStory.content.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    );
  };

  const renderStoryList = () => {
    const category = storiesData[selectedCategory];
    return (
      <div className="stories-list">
        <h2>
          <i className={`fas fa-${getCategoryIcon(selectedCategory)}`}></i>
          {category.title} {category.icon}
        </h2>
        <p className="category-description">{category.description}</p>
        <div className="story-cards">
          {category.stories.map((story) => (
            <div
              key={story.id}
              className="story-card"
              onClick={() => handleStoryClick(story)}
            >
              <div className="story-card-content">
                <div className="story-thumbnail">
                  <i className="fas fa-book"></i>
                  {story.thumbnail}
                </div>
                <h3>{story.title}</h3>
                <div className="story-meta">
                  <span><i className="fas fa-star"></i> {story.readingLevel}</span>
                  <span><i className="fas fa-hourglass-half"></i> {story.duration}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'moral':
        return 'heart';
      case 'adventure':
        return 'compass';
      case 'bedtime':
        return 'moon';
      default:
        return 'book';
    }
  };

  const renderCategories = () => {
    return (
      <div className="categories-grid">
        {Object.entries(storiesData).map(([key, category]) => (
          <div
            key={key}
            className="category-card"
            onClick={() => handleCategoryClick(key)}
          >
            <div className="category-card-content">
              <span className="category-icon">
                <i className={`fas fa-${getCategoryIcon(key)} category-fa-icon`}></i>
                {category.icon}
              </span>
              <h3>{category.title}</h3>
              <p>{category.description}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="stories-container">
      <nav className="nav-bar">
        {(selectedCategory || selectedStory) && (
          <button className="back-button" onClick={handleBackClick}>
            <i className="fas fa-arrow-left"></i> Back
          </button>
        )}
        {!selectedCategory && (
          <Link to="/" className="home-link">
            <i className="fas fa-home"></i> Back to Home
          </Link>
        )}
      </nav>
      <div className="content">
        <h1><i className="fas fa-book-reader"></i> Stories</h1>
        {!selectedCategory && renderCategories()}
        {selectedCategory && !selectedStory && renderStoryList()}
        {selectedStory && renderStoryContent()}
      </div>
    </div>
  );
}

export default Stories; 