import React, { useState } from 'react';
import './Share.css';

function Share({ title, url }) {
  const [copySuccess, setCopySuccess] = useState(false);

  // If no URL is provided, use the current page URL
  const shareUrl = url || window.location.href;

  const handleShare = async (platform) => {
    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(`${title} ${shareUrl}`)}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(shareUrl)}`,
      telegram: `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(title)}`,
      email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(shareUrl)}`
    };

    if (platform === 'copy') {
      try {
        await navigator.clipboard.writeText(shareUrl);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    } else {
      window.open(shareUrls[platform], '_blank', 'width=600,height=400');
    }
  };

  return (
    <div className="share-container">
      <div className="share-options">
        <button onClick={() => handleShare('facebook')} className="share-option facebook" title="Share on Facebook">
          <i className="fab fa-facebook-f"></i>
        </button>
        <button onClick={() => handleShare('whatsapp')} className="share-option whatsapp" title="Share on WhatsApp">
          <i className="fab fa-whatsapp"></i>
        </button>
        <button onClick={() => handleShare('twitter')} className="share-option twitter" title="Share on Twitter">
          <i className="fab fa-twitter"></i>
        </button>
        <button onClick={() => handleShare('telegram')} className="share-option telegram" title="Share on Telegram">
          <i className="fab fa-telegram-plane"></i>
        </button>
        <button onClick={() => handleShare('email')} className="share-option email" title="Share via Email">
          <i className="fas fa-envelope"></i>
        </button>
        <button onClick={() => handleShare('copy')} className="share-option copy" title="Copy Link">
          <i className="fas fa-link"></i>
        </button>
      </div>
      
      {copySuccess && (
        <div className="copy-success">
          Link copied to clipboard!
        </div>
      )}
    </div>
  );
}

export default Share; 