import React from 'react';
import { Link } from 'react-router-dom';
import './Spellings.css';

function Spellings() {
  const spellingCategories = [
    {
      title: "Basic Words",
      description: "Start with simple everyday words perfect for beginners",
      icon: "📚",
      words: "cat, dog, hat, run, jump",
      path: "basic"
    },
    {
      title: "Common Words",
      description: "Practice frequently used words in daily life",
      icon: "✏️",
      words: "friend, school, happy, learn",
      path: "common"
    },
    {
      title: "Advanced Words",
      description: "Challenge yourself with more complex vocabulary",
      icon: "🎯",
      words: "beautiful, adventure, knowledge",
      path: "advanced"
    },
    {
      title: "Phonics & CVC Words",
      description: "Learn consonant-vowel-consonant word patterns",
      icon: "🔤",
      words: "cat, dog, bat, rat, pen",
      path: "Phonics & CVC Words"
    },
    {
      title: "Blends, Digraphs & Sight Words",
      description: "Master word combinations and common sight words",
      icon: "🔡",
      words: "ship, chat, thin, that, when",
      path: "Blends, Digraphs & Sight Words"
    },
    {
      title: "Word Families & Spelling Rules",
      description: "Explore word patterns and spelling rules",
      icon: "📖",
      words: "cake, bake, make, lake, fake",
      path: "Word Families & Spelling Rules"
    },
    {
      title: "Spelling Patterns & Trick Words",
      description: "Tackle challenging spelling patterns and tricky words",
      icon: "🧠",
      words: "know, knee, write, wrong, gnome",
      path: "Spelling Patterns & Trick Words"
    }
  ];

  return (
    <div className="spellings-container">
      <nav className="nav-bar">
        <Link to="/" className="back-button">← Back to Home</Link>
      </nav>
      <div className="content">
        <h1>Let's Learn Spellings!</h1>
        <div className="spellings-grid">
          {spellingCategories.map((category, index) => (
            <Link 
              to={`/spellings/${category.path}`} 
              key={index} 
              className="spelling-card"
            >
              <span className="category-icon">{category.icon}</span>
              <h3>{category.title}</h3>
              <p>{category.description}</p>
              <div className="word-preview">
                {category.words}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Spellings; 