import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import confetti from 'canvas-confetti';
import spellingCategories from '../data/spellingCategories.json';
import './WordLevel.css';

// Success sound URL - cheerful, kid-friendly sound
const SUCCESS_SOUND_URL = 'https://assets.mixkit.co/active_storage/sfx/2018/success-1-6297.wav';

const getLevelDescription = (category, level) => {
  switch(category) {
    case 'basic':
      switch(level) {
        case 1: return "Simple 3-letter words";
        case 2: return "4-letter everyday words";
        case 3: return "Common objects & places";
        case 4: return "Colors & shapes";
        case 5: return "Numbers & counting";
        default: return "";
      }
    case 'common':
      switch(level) {
        case 1: return "Action words & emotions";
        case 2: return "School & daily life";
        case 3: return "Family & home";
        case 4: return "Days & months";
        case 5: return "Animals & nature";
        default: return "";
      }
    case 'advanced':
      switch(level) {
        case 1: return "Descriptive words";
        case 2: return "Learning & growth";
        case 3: return "Character & achievement";
        case 4: return "Science & discovery";
        case 5: return "Challenging spellings";
        default: return "";
      }
    default:
      return "";
  }
};

// Shuffle array using Fisher-Yates algorithm
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

// Enhanced pronunciation helper
const getEnhancedPronunciation = (word) => {
  // Basic syllable splitting
  const syllables = word.toLowerCase().match(/[^aeiouy]*[aeiouy]+(?:[^aeiouy]*$|[^aeiouy](?=[^aeiouy]))?/gi) || [word];
  
  // Simple phonetic transcription
  const phonetic = word.toLowerCase()
    .replace(/th/g, 'θ')
    .replace(/ch/g, 'tʃ')
    .replace(/sh/g, 'ʃ')
    .replace(/ph/g, 'f')
    .replace(/[aeiou]+/g, match => {
      switch(match) {
        case 'a': return 'æ';
        case 'e': return 'ɛ';
        case 'i': return 'ɪ';
        case 'o': return 'ɒ';
        case 'u': return 'ʌ';
        default: return match;
      }
    });

  return {
    syllables,
    phonetic,
    stressIndex: 0 // Default stress on first syllable
  };
};

function WordLevel() {
  const { category } = useParams();
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCelebration, setShowCelebration] = useState(false);
  const [completedWords, setCompletedWords] = useState(0);
  const [showHelp, setShowHelp] = useState(false);
  const [isWrongSpelling, setIsWrongSpelling] = useState(false);
  const [shuffledWords, setShuffledWords] = useState([]);
  const [playbackRate, setPlaybackRate] = useState(0.8);
  const [showPronunciation, setShowPronunciation] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState('female');
  const [voices, setVoices] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const successSoundRef = useRef(new Audio(SUCCESS_SOUND_URL));

  const categoryData = spellingCategories[category];

  useEffect(() => {
    // Shuffle words when level changes
    const words = categoryData.levels[selectedLevel];
    setShuffledWords(shuffleArray(words));
    setCurrentWordIndex(0);
    setUserInput('');
    setIsCorrect(false);
    setShowSuccess(false);
    setCompletedWords(0);
  }, [selectedLevel, category]);

  // Initialize voices when component mounts
  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices().filter(voice => 
        voice.lang.startsWith('en')
      );
      setVoices(availableVoices);
    };

    loadVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }

    return () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  useEffect(() => {
    // Configure success sound
    successSoundRef.current.volume = 0.5; // Set volume to 50%
    
    return () => {
      // Cleanup
      successSoundRef.current.pause();
      successSoundRef.current.currentTime = 0;
    };
  }, []);

  const currentWord = shuffledWords[currentWordIndex] || '';

  const triggerCelebration = () => {
    setShowCelebration(true);
    
    // Confetti animation
    const duration = 3000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        return;
      }

      const particleCount = 50 * (timeLeft / duration);

      // Random confetti burst
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      });
    }, 250);

    // Hide celebration message after animation
    setTimeout(() => {
      setShowCelebration(false);
      setCompletedWords(0);
    }, duration);
  };

  const speakWord = (rate = playbackRate) => {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
    }

    const utterance = new SpeechSynthesisUtterance(currentWord);
    utterance.rate = rate;
    utterance.lang = 'en-US';

    // Select voice based on gender preference
    const voiceOptions = voices.filter(voice => {
      const isFemale = voice.name.toLowerCase().includes('female') || 
                      voice.name.toLowerCase().includes('fiona') ||
                      voice.name.toLowerCase().includes('samantha');
      return selectedVoice === 'female' ? isFemale : !isFemale;
    });

    if (voiceOptions.length > 0) {
      utterance.voice = voiceOptions[0];
    }

    utterance.onstart = () => setIsPlaying(true);
    utterance.onend = () => setIsPlaying(false);
    utterance.onerror = () => setIsPlaying(false);

    speechSynthesis.speak(utterance);
  };

  const handleSpeedChange = (speed) => {
    setPlaybackRate(speed);
    speakWord(speed);
  };

  const togglePronunciation = () => {
    setShowPronunciation(!showPronunciation);
    if (!showPronunciation) {
      speakWord();
    }
  };

  const playSuccessSound = () => {
    successSoundRef.current.currentTime = 0; // Reset to start
    successSoundRef.current.play().catch(error => {
      console.log('Error playing success sound:', error);
    });
  };

  const handleInputChange = (e) => {
    const input = e.target.value.toLowerCase();
    setUserInput(input);
    
    if (input === currentWord.toLowerCase()) {
      setIsCorrect(true);
      setShowSuccess(true);
      setCompletedWords(prev => prev + 1);
      setIsWrongSpelling(false);
      setShowHelp(false);
      
      // Play success sound
      playSuccessSound();
      
      setTimeout(() => {
        if (currentWordIndex === shuffledWords.length - 1) {
          triggerCelebration();
        }
        handleNextWord();
      }, 1000);
    } else if (input.length >= currentWord.length || 
              (input.length > 2 && !currentWord.toLowerCase().startsWith(input))) {
      setIsWrongSpelling(true);
    } else {
      setIsWrongSpelling(false);
    }
  };

  const handleNextWord = () => {
    if (currentWordIndex < shuffledWords.length - 1) {
      setCurrentWordIndex(currentWordIndex + 1);
    } else {
      // Reshuffle words when starting over
      setShuffledWords(shuffleArray(shuffledWords));
      setCurrentWordIndex(0);
    }
    setUserInput('');
    setIsCorrect(false);
    setShowSuccess(false);
    setIsWrongSpelling(false);
    setShowHelp(false);
  };

  const toggleHelp = () => {
    setShowHelp(!showHelp);
    if (!showHelp) {
      speakWord();
    }
  };

  useEffect(() => {
    // Update the document title based on the current category
    document.title = `${category} Spellings - Kids Corner`;

    // Cleanup function to reset title when component unmounts
    return () => {
      document.title = 'Kids Corner';
    };
  }, [category]);

  return (
    <main className="word-level-container" role="main">
      <nav className="nav-bar" role="navigation" aria-label="Main navigation">
        <Link to="/spellings" className="back-button" aria-label="Go back to spellings menu">← Back to Spellings</Link>
      </nav>
      
      <div className="content">
        <header>
          <h1>
            <span className="category-icon" role="img" aria-label="Category icon">{categoryData.icon}</span>
            {categoryData.title}
          </h1>
        </header>

        <div className="sections-container">
          <section className="level-section" aria-labelledby="level-section-title">
            <h2 id="level-section-title">Choose Your Level</h2>
            <div className="level-buttons" role="radiogroup" aria-label="Spelling difficulty levels">
              {Object.keys(categoryData.levels).map((level) => (
                <button
                  key={level}
                  className={`level-button ${selectedLevel === parseInt(level) ? 'active' : ''}`}
                  onClick={() => {
                    setSelectedLevel(parseInt(level));
                    setCurrentWordIndex(0);
                  }}
                  role="radio"
                  aria-checked={selectedLevel === parseInt(level)}
                  aria-label={`Level ${level}: ${getLevelDescription(category, parseInt(level))}`}
                >
                  <div className="level-button-content">
                    <div className="level-number">Level {level}</div>
                    <div className="level-description">{getLevelDescription(category, parseInt(level))}</div>
                  </div>
                </button>
              ))}
            </div>
          </section>

          <section className="word-section" aria-labelledby="practice-section-title">
            <h2 id="practice-section-title">Practice Words</h2>
            <div className="word-display">
              <div className="word-card" role="region" aria-live="polite">
                {showCelebration ? (
                  <div className="celebration-message" role="alert" aria-live="assertive">
                    <h2>🎉 Congratulations! 🎉</h2>
                    <p>You've completed Level {selectedLevel}!</p>
                    <div className="stats">
                      <span>Words Mastered: {shuffledWords.length}</span>
                    </div>
                  </div>
                ) : (
                  <div className="practice-container">
                    <div className="pronunciation-section">
                      <div className="pronunciation-controls">
                        <button 
                          className={`speak-button primary ${isPlaying ? 'playing' : ''}`} 
                          onClick={() => speakWord()}
                          disabled={isPlaying}
                          aria-label={`Listen to the word${isPlaying ? ' (playing)' : ''}`}
                        >
                          {isPlaying ? '🔊 Playing...' : '🎤 Listen to the Word'}
                        </button>
                        <div className="voice-controls" role="radiogroup" aria-label="Voice selection">
                          <button 
                            className={`voice-button ${selectedVoice === 'female' ? 'active' : ''}`}
                            onClick={() => setSelectedVoice('female')}
                            role="radio"
                            aria-checked={selectedVoice === 'female'}
                            aria-label="Select female voice"
                          >
                            👩 Female Voice
                          </button>
                          <button 
                            className={`voice-button ${selectedVoice === 'male' ? 'active' : ''}`}
                            onClick={() => setSelectedVoice('male')}
                            role="radio"
                            aria-checked={selectedVoice === 'male'}
                            aria-label="Select male voice"
                          >
                            👨 Male Voice
                          </button>
                        </div>
                      </div>

                      <button 
                        className="pronunciation-button" 
                        onClick={togglePronunciation}
                        aria-expanded={showPronunciation}
                        aria-controls="pronunciation-guide"
                      >
                        📖 {showPronunciation ? 'Hide' : 'Show'} Pronunciation Guide
                      </button>
                      
                      {showPronunciation && (
                        <div 
                          id="pronunciation-guide" 
                          className="pronunciation-guide"
                          role="region"
                          aria-label="Pronunciation guide"
                        >
                          <div className="word-breakdown">
                            <h3>{currentWord}</h3>
                            <div className="phonetic-text" aria-label="Phonetic pronunciation">
                              /{getEnhancedPronunciation(currentWord).phonetic}/
                            </div>
                            <div className="syllables" role="list" aria-label="Word syllables">
                              {getEnhancedPronunciation(currentWord).syllables.map((syllable, index) => (
                                <span 
                                  key={index} 
                                  className={index === getEnhancedPronunciation(currentWord).stressIndex ? 'stressed' : ''}
                                  onClick={() => speakWord(0.8)}
                                  role="listitem"
                                  aria-label={`Syllable ${syllable}${index === getEnhancedPronunciation(currentWord).stressIndex ? ' (stressed)' : ''}`}
                                >
                                  {syllable}
                                </span>
                              ))}
                            </div>
                          </div>
                          <div className="playback-controls" role="group" aria-label="Playback speed controls">
                            <button 
                              className={`speed-button ${playbackRate === 0.5 ? 'active' : ''}`}
                              onClick={() => handleSpeedChange(0.5)}
                            >
                              0.5x
                            </button>
                            <button 
                              className={`speed-button ${playbackRate === 0.8 ? 'active' : ''}`}
                              onClick={() => handleSpeedChange(0.8)}
                            >
                              Normal
                            </button>
                            <button 
                              className={`speed-button ${playbackRate === 1 ? 'active' : ''}`}
                              onClick={() => handleSpeedChange(1)}
                            >
                              1x
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {isWrongSpelling && (
                      <button 
                        className="help-button wrong-spelling" 
                        onClick={toggleHelp} 
                        aria-label="Show help with spelling"
                        title="Need help? Click to see the word"
                      >
                        💡
                      </button>
                    )}
                    {showHelp && (
                      <div className="help-text" role="alert">
                        <div className="pronunciation">
                          <span className="word">{currentWord}</span>
                          <span className="phonetic" aria-label="Phonetic spelling">
                            /{getEnhancedPronunciation(currentWord).phonetic}/
                          </span>
                        </div>
                        <div className="hint">Click 🎤 to hear it again</div>
                      </div>
                    )}
                    <div className="input-container">
                      <label className="visually-hidden" htmlFor="spelling-input">
                        Type the word: {currentWord}
                      </label>
                      <input
                        id="spelling-input"
                        type="text"
                        value={userInput}
                        onChange={handleInputChange}
                        placeholder="Type the word here..."
                        className={`spelling-input ${isCorrect ? 'correct' : ''} ${isWrongSpelling ? 'wrong' : ''}`}
                        autoFocus
                        aria-invalid={isWrongSpelling}
                        aria-describedby={showHelp ? "spelling-help" : undefined}
                      />
                      {showSuccess && (
                        <span className="success-icon" role="status" aria-label="Correct spelling!">✓</span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="word-controls" role="status" aria-live="polite">
                <div className="word-counter">
                  Word {currentWordIndex + 1} of {shuffledWords.length}
                </div>
                <div 
                  className="progress-bar" 
                  role="progressbar" 
                  aria-valuenow={(completedWords / shuffledWords.length) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-label="Progress through current level"
                >
                  <div 
                    className="progress-fill" 
                    style={{ width: `${(completedWords / shuffledWords.length) * 100}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}

export default WordLevel; 